import React from "react";
import Navbar from "./pages/navbar/Navbar";
import Navigation from "./pages/Navigation";
import Footer from "./pages/footer/Footer";
import ScrollToTop from "./components/ScrollToTop";

function App() {

  return (
    <div className="App">
      <ScrollToTop />
      <Navbar />
      <Navigation />
      <Footer />
    </div>
  );
}

export default App;

import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

// Lazy load components.
const Landing = React.lazy(() => import("./landing/Landing"));
const Homepage = React.lazy(() => import("./homepage/Homepage"));
const Login = React.lazy(() => import("./auth/Login"));
const Signup = React.lazy(() => import("./auth/Signup"));
const PasswordResetRequest = React.lazy(() => import("./auth/PasswordResetRequest"));
const PasswordResetReset = React.lazy(() => import("./auth/PasswordResetReset"));
const Artist = React.lazy(() => import("./artists/artist/Artist"));
const Artists = React.lazy(() => import("./artists/artists/Artists"));
const AddCourse = React.lazy(() => import("./admin/AddCourse"));
const Admin = React.lazy(() => import("./admin/Admin"));
const SongReviewArtist = React.lazy(() => import("./homepage/homepage_artist/pages/song_review/SongReviewArtist"));
const SongReviewUser = React.lazy(() => import("./homepage/homepage_user/pages/song_review/SongReviewUser"));
const SongReviewRequest = React.lazy(() => import("./song_review/SongReviewRequest"));
const LandingArtists = React.lazy(() => import("./landing/components/artists/LandingArtists"));

const Navigation = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/auth/login" element={<Login />} />
        <Route exact path="/auth/signup" element={<Signup />} />
        <Route exact path="/auth/password-reset/request" element={<PasswordResetRequest />} />
        <Route exact path="/auth/password-reset/reset" element={<PasswordResetReset />} />

        <Route exact path="/home" element={<Homepage />} />
        
        <Route exact path="/user/song-review/:songReviewId" element={<SongReviewUser />} />
        <Route exact path="/artist/song-review/:songReviewId" element={<SongReviewArtist />} />

        <Route exact path="/artists" element={<Artists />} />
        <Route exact path="/artist/:artistId" element={<Artist />} />

        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/add-course" element={<AddCourse />} />

        <Route exact path="/landing-artists" element={<LandingArtists />} />

        <Route extact path="/song-review/request/:artistId" element={<SongReviewRequest />} />
      </Routes>
    </Suspense>
  );
};

export default Navigation;

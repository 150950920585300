import { extendTheme } from "@chakra-ui/react";

const colors = {
  // Main colors
  primary: 'black',
  secondary: 'gray.700',
  accent: '#28a745',
  background: '#f8f9fa',
  text: '#343a40',
  // Hover colors
  primaryHover: 'gray.700',
  secondaryHover: 'gray.600',
  whiteHover: 'gray.200',
}

const sizes = {
  landing: {
    header1: '106px',
    header2: '60px',
  },
  button: {
    primary: {
      height: '50px',
      width: '300px',
      fontSize: '20px'
    },
    input: {
      fontSize: '14px',
    }
  },
  input: {
    primary: {
      height: '46px',
      width: '300px',
    }
  }
}

const fonts = {
  primary: 'Roboto, sans-serif',
  secondary: 'Open Sans, sans-serif'
}

const theme = extendTheme({
  fonts: fonts,
  fontSizes: {
    '5xl': '3rem',
    '6xl': '4rem',
    '7xl': '5rem',
    '8xl': '6rem',
    '9xl': '7rem',
  },
  sizes: sizes,
  colors: colors,
  layerStyles: {
    // General
    tag: {
      padding: '5px 10px',
      marginLeft: '5px',
      marginRight: '5px',
      borderRadius: '20px',
      color: 'white',
      background: 'rgb(163, 163, 163)',
      fontSize: '12px',
    },
    // Courses and Masterclasses page
    cardBox: {
      border: '2.5px solid black',
      borderRadius: '10px',
      padding: '14px 28px 28px 28px',
      width: '33vw',
      _hover: {
        transform: 'scale(1.03)',
        textDecoration: 'none',
      }
    },
    cardLinkBox: {
      transition: 'transform .2s',
      _hover: {
        transform: 'scale(1.03)',
      }
    },
    cardHeader: {
      fontWeight: 'bold', 
      paddingBottom: '15px',
      fontSize: '2em',
      textAlign: 'center',
    },
    cardImage: {
      width: '25vw',
      height: '25vw',
      objectFit: 'cover',
      borderRadius: '2rem' 
    },
    // Course and Masterclass page
    headBox: {
      padding: '14px 28px 28px 28px',
      border: '3px solid black',
      borderRadius: '10px',
    },
    headBoxImage: {
      width: '20vw',
      height: '20vw',
      objectFit: 'cover', 
      borderRadius: '2rem' 
    },
    textBox: {
      width: '60vw',
      padding: '28px', 
      marginLeft: '2vw',
      border: '3px solid black',
      borderRadius: '10px',
    },
    textBoxText: {
      fontSize: '22px',
      textAlign: 'justify',
      marginLeft: '20px',
      color: '#555555',
    },
    salesBox: {
      border: '3px solid black',
      borderRadius: '10px',
    },
    salesBoxTabs: {
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid gray',
      width: '100%',
    }
  },
  components: {
    Button: {     
      variants: {
        primary: {
          height: sizes.button.primary.height,
          width: sizes.button.primary.width,
          background: colors.primary,
          border: '2px solid black',
          fontSize: sizes.button.primary.fontSize,
          color: 'white',
          _hover: {
            background: colors.primaryHover,
          }
        },
        loginInput: {
          padding: '0px 25px 0px 25px',
          margin: 'auto',
          marginTop: '10px',
          marginRight: '21px',
          background: colors.secondary,
          fontSize: sizes.button.input.fontSize,
          color: 'white',
          _hover: {
            background: colors.secondaryHover,
          }
        },
        signupInput: {
          padding: '0px 25px 0px 25px',
          margin: 'auto',
          marginTop: '5px',
          marginRight: '21px',
          background: colors.secondary,
          fontSize: sizes.button.input.fontSize,
          color: 'white',
          _hover: {
            background: colors.secondaryHover,
          }
        },
        navLogin: {
          width: '120px',
          height: '45px',
          marginRight: '13px',
          color: 'white',
          background: colors.primary,
          border: '1.5px solid white',
          borderRadius: '5px',
          _hover: {
            background: colors.primaryHover,
          }
        },
        navSignup: {
          width: '120px',
          height: '45px',
          color: colors.primary,
          background: 'white',
          marginRight: '13px',
          _hover: {
            background: colors.whiteHover,
          }
        },
        hero: {
          width: '210px',
          height: '90px',
          margin: '0px 10px 0px 10px',
          background: colors.primary,
          color: 'white',
          fontSize: '22px',
          _hover: {
            background: colors.primaryHover,
          }
        },
      },
    },
    Input: {
      variants: {
        primary: () => ({
          field: {
            width: sizes.button.primary.width,
            height: sizes.button.primary.height,
            background: 'white',
            color: colors.primary,
            border: `2px solid ${colors.primary}`,
            borderRadius: '10px',
          }
        })
      }
    }
  },
})

export default theme;
import React, { useContext } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import { FaUser, FaBars } from "react-icons/fa";
import { StoreContext } from "../../helpers/context";

import '../../App.scss';

const Navbar = () => {
  const navPadding = "5px";

  const {
    loggedIn,
    setLoggedIn,
    userType,
    setUserData,
    setArtistsData,
  } = useContext(StoreContext);

  const navigate = useNavigate();
  const location = useLocation(); // Correctly get the location object

  const styles = {
    container: {
      marginWidth: '3%',
    },
    text: {
      marginWidth: '25px',
      fontSize: '16px',
    },
    button: {
      marginWidth: '13px',
    },
  };

  // Determine the current page
  const isHomePage = location.pathname === "/home";
  const isArtistsPage = location.pathname === "/artists";
  const isAdminPage = location.pathname === "/admin";
  const isLandingPage = location.pathname === "/";
  const isArtistsOrLandingPage = isArtistsPage || isLandingPage;

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem("x-access-token");
    setLoggedIn(false);
    setArtistsData([]);
    setUserData(null);
    navigate("/");
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      position="fixed"
      width="100vw"
      zIndex={2}
      padding="8px 0px"
      background="black"
      color="white"
      justifyContent="space-between"
      alignItems="center"
    >
      {/* Logo */}
      <Flex
        as={RouterLink}
        to={loggedIn ? "/home" : "/"}
        justifyContent="left"
        alignItems="center"
        marginLeft={styles.container.marginWidth}
      >
        <Image src={`${process.env.PUBLIC_URL}/logos/logo-opposite-beta.svg`} alt="Logo" />
      </Flex>

      {/* Navigation Links */}
      {isMobile ? (
        /* Mobile Menu */
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<FaBars size="24px" />}
            variant="outline"
            colorScheme="white"
            border="none"
            marginRight="6%"
          />
          <MenuList
            color="black"
            boxShadow="md"
            borderColor="gray.200"
            borderWidth="1px"
            borderRadius="md"
            bg="white"
            py="2"
          >
            <MenuItem
              as={RouterLink}
              to={loggedIn ? "/home" : "/"}
              _hover={{ bg: "blue.50" }}
              _focus={{ bg: "blue.100" }}
              px="4"
              py="2"
              borderRadius="md"
            >
              Home
            </MenuItem>
            <MenuItem
              as={RouterLink}
              to="/artists"
              _hover={{ bg: "blue.50" }}
              _focus={{ bg: "blue.100" }}
              px="4"
              py="2"
              borderRadius="md"
            >
              Artists
            </MenuItem>
            {!loggedIn ? (
              <>
                <MenuItem
                  as={RouterLink}
                  to="/auth/login"
                  _hover={{ bg: "blue.50" }}
                  _focus={{ bg: "blue.100" }}
                  px="4"
                  py="2"
                  borderRadius="md"
                >
                  Log In
                </MenuItem>
                <MenuItem
                  as={RouterLink}
                  to="/auth/signup"
                  _hover={{ bg: "blue.50" }}
                  _focus={{ bg: "blue.100" }}
                  px="4"
                  py="2"
                  borderRadius="md"
                >
                  Sign Up
                </MenuItem>
              </>
            ) : (
              <>
                {userType === 'admin' && (
                  <MenuItem
                    as={RouterLink}
                    to="/admin"
                    _hover={{ bg: "blue.50" }}
                    _focus={{ bg: "blue.100" }}
                    px="4"
                    py="2"
                    borderRadius="md"
                  >
                    Admin
                  </MenuItem>
                )}
                <MenuItem
                  onClick={handleLogout}
                  _hover={{ bg: "blue.50" }}
                  _focus={{ bg: "blue.100" }}
                  px="4"
                  py="2"
                  borderRadius="md"
                >
                  Logout
                </MenuItem>
              </>
            )}
          </MenuList>
        </Menu>
      ) : (
        /* Desktop Menu */
        <Flex
          justifyContent="center"
          alignItems="center"
          marginRight={styles.container.marginWidth}
        >
          <Text
            as={RouterLink}
            to={loggedIn ? "/home" : "/"}
            p={navPadding}
            fontSize="lg"
            textDecoration={isHomePage || isLandingPage ? "underline" : "none"}
            color={isHomePage || isLandingPage ? "white" : "gray.400"}
            fontWeight={isHomePage || isLandingPage ? "bold" : "normal"}
            marginRight={styles.text.marginWidth}
            _hover={{
              color: "white",
              textDecoration: "underline",
            }}
          >
            Home
          </Text>
          <Text
            as={RouterLink}
            to="/artists"
            p={navPadding}
            fontSize="lg"
            textDecoration={isArtistsPage ? "underline" : "none"}
            color={isArtistsPage ? "white" : "gray.400"}
            fontWeight={isArtistsPage ? "bold" : "normal"}
            marginRight={styles.text.marginWidth}
            _hover={{
              color: "white",
              textDecoration: "underline",
            }}
          >
            Artists
          </Text>
          {!loggedIn ? (
            <>
              <Button
                variant="navLogin"
                as={RouterLink}
                to="/auth/login"
                marginRight={styles.button.marginWidth}
              >
                Log In
              </Button>
              <Button
                variant="navSignup"
                as={RouterLink}
                to="/auth/signup"
                marginRight={styles.button.marginWidth}
              >
                Sign Up
              </Button>
            </>
          ) : (
            <>
              {userType === 'admin' && (
                <Text
                  as={RouterLink}
                  to="/admin"
                  p={navPadding}
                  fontSize="lg"
                  textDecoration={isAdminPage ? "underline" : "none"}
                  color={isAdminPage ? "white" : "gray.400"}
                  fontWeight={isAdminPage ? "bold" : "normal"}
                  marginRight={styles.text.marginWidth}
                  _hover={{
                    color: "white",
                    textDecoration: "underline",
                  }}
                >
                  Admin
                </Text>
              )}
              <div data-testid="menu-user">
                <Menu>
                  <MenuButton as={IconButton} color="black" icon={<FaUser />} size='lg'>
                    User
                  </MenuButton>
                  <MenuList color='black'>
                    <MenuItem data-testid="menu-item-logout" onClick={handleLogout}>Logout</MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default Navbar;

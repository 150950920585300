import React from "react";
import PropTypes from 'prop-types';

export const StoreContext = React.createContext(null);

// useContext for navbar
function StoreProvider(
  { 
    children, 
    loggedInInit,
    userTypeInit, 
    userDataInit, 
    artistsDataInit,
    songReviewsDataInit
  }) {
  
  const [loggedIn, setLoggedIn] = React.useState(
    loggedInInit !== undefined ? loggedInInit :
    (localStorage.getItem("x-access-token") ? true : false)
  );
  const [userType, setUserType] = React.useState(userTypeInit !== undefined ? userTypeInit : "");
  const [userData, setUserData] = React.useState(userDataInit !== undefined ? userDataInit : null);
  const [artistsData, setArtistsData] = React.useState(artistsDataInit !== undefined ? artistsDataInit : []);
  const [songReviewsData, setSongReviewsData] = React.useState(songReviewsDataInit !== undefined ? songReviewsDataInit : []);

  return (
    <StoreContext.Provider value={{
      loggedIn, setLoggedIn,
      userType, setUserType,
      userData, setUserData,
      artistsData, setArtistsData,
      songReviewsData, setSongReviewsData,
    }}>
      {children}
    </StoreContext.Provider>
  );
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
  loggedInInit: PropTypes.bool,
  userTypeInit: PropTypes.string,
  userDataInit: PropTypes.object,
  artistsDataInit: PropTypes.array,
  songReviewsDataInit: PropTypes.array,
};

export default StoreProvider;

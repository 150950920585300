import React from 'react';
import {
  Box,
  Flex,
  IconButton,
  Image,
  Link,
  Text,
  useColorModeValue,
  VStack,
  Stack
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { FaTwitter, FaInstagramSquare } from "react-icons/fa";

const Footer = () => {
  const textColor = useColorModeValue('gray.600', 'gray.200');
  const iconColor = useColorModeValue('black', 'blue.200');
  const iconSize = '24px';

  return (
    <Box
      as="footer"
      mt={10}
      pt={10}
      pb={10}
      px={{ base: 4, md: 10 }}
      borderTopWidth="2px"
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      boxShadow="0px -6px 6px -1px rgba(0, 0, 0, 0.1), 0px -3px 4px -1px rgba(0, 0, 0, 0.06)"
    >
      <Flex
        direction={{ base: 'row', md: 'row' }}
        wrap="wrap"
        alignItems="center"
        justifyContent={{ base: 'center', sm: 'space-between', md: 'space-between' }}
        px={5}
      >
        <Box>
          <VStack mx={'auto'}>
            <Image src={`${process.env.PUBLIC_URL}/logos/logo.svg`} alt='Sonikly logo' width={{ base: '120px', md: '140px' }} />
            <Text color={textColor} fontSize={{ base: '12px', md: '16px' }} >
              © Sonikly 2024, All Rights Reserved
            </Text>
          </VStack>
        </Box>

        <Stack direction="row" spacing={2} align="center" mt={{base: '4', sm: '0px'}}>
          <Link href="mailto:contact@sonikly.io" target="_blank" rel="noopener noreferrer">
            <IconButton
              aria-label="Email"
              icon={<EmailIcon />}
              fontSize={iconSize}
              color={iconColor}
              variant="ghost"
            />
          </Link>
          <Link href="https://instagram.com/sonikly.io" target="_blank" rel="noopener noreferrer">
            <IconButton
              aria-label="Instagram"
              icon={<FaInstagramSquare />}
              fontSize={iconSize}
              color={iconColor}
              variant="ghost"
            />
          </Link>
        </Stack>

      </Flex>
    </Box>
  );
};

export default Footer;
